import React, { useEffect, useState } from "react";
import {graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout"
import Seo from "../components/seo"
import imgSrc from '../images/img-selection.jpg';
import LangSelector from "../components/LangSelector";
import bgShadowSrc from '../images/bg-shadow.jpg';
import unitsData from '../../data.json';

const DATA = [
  {
    id: 'a',
    path: 'M1618,778.33v38l-116.67-24.67v-13.33l-38.67-16v-180h65.33v-48h90v48l39.33,1.33-20.67,196-18.67-1.33Z',
    transform: '',
    point: 'translate(1500 580)',
    x: -11,
    y: 10,
    labelX: -10,
    labelY: 40
  },
   {
    id: 'b',
    path: 'M1289.5,592l20.5,92-20.5,5.5,25,96-136.5,23-20-23-41.5-174,83.5-19.5-4-16.5,33.5-6.5,4.5,16,25.2-.6,5.8,11.6,24.5-4Z',
    transform: '',
    point: 'translate(1138 600)',
    x: -10,
    y: 10,
    labelX: -10,
    labelY: 40
  },
  {
    id: 'c',
    path: 'M894.03,589.62l3.47-15.62,32.5,5-3,17.5,46,9v12.5l58,13-19.5,104.5-61.5-12-15,77-30.5-5-4,18-37.5-5,4-22-60-15.5,36-187,8-2.5,43.03,8.12Z',
    transform: '',
    point: 'translate(860 580)',
    x: -11,
    y: 10,
    labelX: -10,
    labelY: 40
  },
  {
    id: 'd',
    path: 'M629.5,588l4,18,47.5-9,22,101.5-44,10.5,11.5,55-30,23,6.5,21.5-60,12-4.5-18.5-70,14-30-195.5,93-21.5-6-18.5,33.38-5.83,3.12,18,23.5-4.67Z',
    transform: '',
    point: 'translate(504 610)',
    x: -11,
    y: 10,
    labelX: -10,
    labelY: 40
  },
  {
    id: 'e',
    path: 'M241.5,584.5l18,3.5,3.5-11,43,5.5-3,14.5,60.5,13-3,19.5,45.5,7-19,90-44-8.5-14.5,70-51,20.5-113-29.5,12-187,65-7.5Z',
    transform: '',
    point: 'translate(190 600)',
    x: -10,
    y: 10,
    labelX: -10,
    labelY: 40
  },
  {
    id: 'f',
    path: 'M259.5,105l117-38.5,62,49,12.5,62.5,37.5-6.5,21.5,96-37.5,6.5,5.5,19.5-122,27.5-33.5-14-63-202Z',
    transform: '',
    point: 'translate(280 100)',
    x: -10,
    y: 10,
    labelX: -10,
    labelY: 40
  },
  {
    id: 'g',
    path: 'M644.5,84l75,10,4.5-10,40,10,2.5,6.5,15,34-11,51.5,11,3-2.5,13,42.01,9.95-22.51,96.55-53-11-2,15-50.5-13.5-3.5,13.5-36.5-5,1-16.5-28.5-3-32.75-25,51.75-179Z',
    transform: '',
    point: 'translate(660 90)',
    x: -11,
    y: 10,
    labelX: -10,
    labelY: 40
  },
  {
    id: 'h',
    path: 'M926,98l131-37.5,15,74.5,2.5,28,21.5-6.5,23.5,105-23.5,4.5-8.5,21.5-8.5,2.5,1,10.5-34,7.5-4-12-74,16.5-42-176v-38.5Z',
    transform: '',
    point: 'translate(943 90)',
    x: -11,
    y: 10,
    labelX: -10,
    labelY: 40
  },
  {
    id: 'i',
    path: 'M1288.5,76.5l135,17.5-25.5,185-42,28-35.5-8.5-2,8.5-37.5-5.5,2.5-12-47-7.5,32-178.5,20-27Z',
    transform: '',
    point: 'translate(1290 90)',
    x: -6,
    y: 10,
    labelX: -10,
    labelY: 40
  }
];

const Path = styled.path``;

const Picker = styled.div`
  position: relative;
  /* display: none; */
  /* background-color: ${({theme}) => theme.colors.primary}; */

  /* &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #303030;
    opacity: 0.3;
    z-index: 1;
  } */

  svg {
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  a {
    text-decoration: none;
    z-index: 2;
  }

  .house-letter {
    font-weight: bold;
    opacity: 0;
    transition: all .5s ease;
    /* stroke: #C63922;
    stroke-width: 0px; */

    circle {
      fill: #ff940a;
    }

    text {
      stroke-width: 0px;
      font-size: 30px;
      fill: #fff;
    }
  }

  .house-label {
    opacity: 0;
    font-weight: bold;

    text {
      stroke-width: 0px;
      font-size: 30px;
      fill: #fff;
    }

    @media(min-width: 768px) {
      opacity: 1;
    }
  }

  a:hover {
    text-decoration: none;
    ${Path} {
      /* opacity: 0.65; */
      opacity: 0.75;
    }

    .house-letter {
      opacity: 0.9;
    }
  }


  ${Path} {
    display: none;
    opacity: 0;
    /* fill: ${({theme}) => theme.colors.secondary}; */
    fill: #eaa548;
    cursor: pointer;
    transition: all .5s ease;

    &.selected {
      opacity: 0.5;
    }

    @media(min-width: 768px) {
      display: inline;

      &.sold {
        opacity: 0.75;
        fill: #292929;
        cursor: auto;

        &:hover {
          opacity: 0.75;
        }
      }
    }
  }

  ${Path}:hover {
    opacity: 0.6;

    &.selected {
      opacity: 0.5;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  @media(min-width: 992px) {
    display: block;
  }
`;

const MobileVersion = styled.div`
  padding: 70px 0;

  a {
    text-decoration: none;
    display: block;
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0.875rem;
    padding: 0.5rem 0.875rem;
    color:  ${({ isActive }) => isActive ? '#fff' : '#ff940a'};
    border: 2px solid #ff940a;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    background-color: ${({ isActive }) => isActive ? '#ff940a' : 'transparent'};
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
    background-color: #ff940a;
    color: #fff;
  }

  @media(min-width: 1200px) {
    display: none;
  }
`;

const Section = styled.div`
  position: relative;
  background-color: #fff;
  background-image: url(${bgShadowSrc});
  background-repeat: repeat-y;
  background-position: top left;
  /* padding: 40px 70px 110px; */


  @media(min-width: 1200px) {
    min-height: calc(100vh - 52px);
  }
`;

const Content = styled.div`
`;

const MobileSubtitle = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: #303030;
  margin-bottom: 1.5rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    width: 60px;
    height: 5px;
    background-color: #ff940a;
    transform: translate(-50%,-50%);
  }

  @media(min-width: 768px) {
    font-size: 1.875rem;
    line-height: 3rem;
  }
`;

const Subtitle = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  font-size: 3.375rem;
  line-height: 3rem;
  text-align: center;
  color: #fefefe;
  margin-bottom: 1.5rem;
  position: relative;
`;

const LeftSubtitle = styled(Subtitle)`
  display: none;
  text-align: left;
  position: absolute;
  bottom: 30px;
  left: 70px;
  text-shadow: -1px 3px 7px rgba(0, 0, 0, 0.72);
  z-index: 2;
  margin-bottom: 0;

  @media(min-width: 1200px) {
    display: block;
  }

  @media(min-width: 1400px) {
    bottom: 70px;
  }
`;

function HoseSelectPage() {
  const [availableUnits, setAvailableUnits] = useState([]);
  const [soldUnits, setSoldUnits] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const available = [];
    const sold = [];

    DATA.forEach(d => {
      const availableUnits = unitsData.filter(i => i.building === d.id.toUpperCase() && i.state === 'available');
      if(availableUnits.length > 0) {
        available.push(d);
      } else {
        sold.push(d);
      }
    });

    setAvailableUnits(available);
    setSoldUnits(sold);
  }, []);

  return (
    <Layout>
      <Seo title={t('seo.house_selection.title')} />
      <Content>
        <Section>
          <LangSelector />
          <Picker>
            <LeftSubtitle as="h1">{t('house_selection.title')}</LeftSubtitle>
            {/* <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1609 1006"> */}
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1920 1080">
              <image href={imgSrc}></image>
                {
                  availableUnits.map(({id, path, transform, point, x, y}) => (
                    <Link key={id} to={`/dum-${id}`}>
                      <Path d={path} transform={transform} />
                      <g transform={point} className="house-letter">
                        <g className="response-scale">
                            <circle cx="0" cy="0" r="30"></circle>
                            <circle cx="0" cy="0" r="30"></circle>
                            <text x={x} y={y}>{id.toUpperCase()}</text>
                        </g>
                      </g>
                    </Link>
                  ))
                }
                {
                  soldUnits.map(({id, path, transform, point, x, y, labelX, labelY}) => (
                    <React.Fragment key={id}>
                      <Path d={path} transform={transform} className="sold" />
                      <g transform={point} className="house-label">
                        <g className="response-scale">
                          <text x={labelX} y={labelY}>{t(`state.sold`)}</text>
                        </g>
                      </g>
                    </React.Fragment>
                  ))
                }
            </svg>
          </Picker>
          <MobileVersion>
            <MobileSubtitle as="h1">{t('house_selection.title')}</MobileSubtitle>
            {
              availableUnits.map(({id}) => (
                <Link key={id} to={`/dum-${id}`}>
                  {t('house_selection.subtitle', {id})}
                </Link>
              ))
            }
          </MobileVersion>
        </Section>
      </Content>
    </Layout>
  )
}

export default HoseSelectPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
